import { configureStore } from "@reduxjs/toolkit";
import errorService from "../features/errorService";
import mobileMenuService from "../features/walletService/mobileMenuService";
import walletServiceProvider from "../features/walletService/walletService";

export default configureStore({
  reducer: {
    walletServiceProvider: walletServiceProvider,
    mobileMenuProvide: mobileMenuService,
    errorServiceProvider: errorService,
  },
});
