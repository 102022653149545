import { useDispatch, useSelector } from "react-redux";
import { DEF_CHAINS } from "../../constants/SystemConstants";
import {
  connectWallet,
  networkSelectDialogToggle,
  PROVIDER_METAMASK,
  walletTypeDialogToggle,
} from "../../features/walletService/walletService";
import { getNetworks } from "../../utils/Networkutil";

function WallectConnectButtonBlock({ customClass }) {
  let dispatch = useDispatch();
  return (
    <a
      className={"primary-button " + customClass}
      onClick={() => dispatch(connectWallet(PROVIDER_METAMASK))}
    >
      <div className="">Connect to a wallet</div>
    </a>
  );
}

function WallectConnectedButtonBlock({ customClass }) {
  // let dispatch = useDispatch();
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );
  let dispatch = useDispatch();

  const changeNetwork = () => {
    dispatch(networkSelectDialogToggle());
  };

  const getNetworkImageByChainId = (chainId) => {
    return getNetworks().find((v) => v.chainId == chainId)?.topMenuLogo;
  };

  return (
    <div
      className={"header-connected-wallet " + customClass}
      onClick={changeNetwork}
    >
      <div className="header-wallet-img">
        <img src="assets/img/custom2/image 1.svg" alt="" />
        <div className="header-wallet-currency-icon">
          {getNetworkImageByChainId(walletInfo.networkChainId) && (
            <img
              src={getNetworkImageByChainId(walletInfo.networkChainId)}
              alt=""
            />
          )}
        </div>
      </div>
      <div className="header-wallet-info">
        <div className="header-wallet-name">
          {walletInfo.accountAddress &&
            `${walletInfo.accountAddress.slice(
              0,
              4
            )}...${walletInfo.accountAddress.slice(-4)}`}
        </div>
        <div className="header-wallet-info-icon">
          <img src="assets/img/custom2/Frame 1000001089.svg" alt="" />
        </div>
      </div>
    </div>
  );
}

function TopWalletButton({ customClass }) {
  // let dispatch = useDispatch();
  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  return (
    <>
      {!walletInfo.isConnected ? (
        <WallectConnectButtonBlock customClass={customClass} />
      ) : (
        <WallectConnectedButtonBlock customClass={customClass} />
      )}
    </>
  );
}
export default TopWalletButton;
