import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  connectWallet,
  networkSelectDialogToggle,
  PROVIDER_METAMASK,
} from "../features/walletService/walletService";
import { MetamaskWebProvider } from "../services/metamask/MetamaskWebProvider";
import { getNetworks } from "../utils/Networkutil";

export default function ChangeNetworkPopup() {
  const [networkList, setNetworkList] = useState([]);

  let showNetworkSelectPopup = useSelector(
    (state) => state.walletServiceProvider.showNetworkSelectPopup
  );

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  const dispatch = useDispatch();

  useEffect(() => {
    setNetworkList(getNetworks());
  }, []);

  useEffect(() => {
    if (currentNetwork.current) {
      handleChainChange();
    }
  }, [walletInfo.isConnected]);

  const currentNetwork = useRef(null);

  const onChainChange = (network) => {
    currentNetwork.current = network;
    if (!walletInfo.isConnected) {
      dispatch(connectWallet(PROVIDER_METAMASK));
    } else {
      handleChainChange();
    }
  };
  const handleChainChange = async () => {
    if (walletInfo.networkChainId != currentNetwork.current.chainId) {
      let isChanged = await MetamaskWebProvider.changeChain(
        walletInfo,
        dispatch,
        currentNetwork.current
      );
      console.log(walletInfo, isChanged, "isChanged");
      if (isChanged) {
        closePopup();
      }
    }
  };

  const addChain = async (network) => {
    if (walletInfo.networkChainId != network.chainId) {
      let isAdded = await MetamaskWebProvider.addChainById(
        network.chainId,
        dispatch
      );
      console.log(walletInfo, isAdded, "isChanged");
      if (isAdded) {
        closePopup();
      }
    }
  };

  const closePopup = () => {
    dispatch(networkSelectDialogToggle());
  };
  return (
    <div
      className={`form_popup_cnt main_form_popup ${
        showNetworkSelectPopup ? "active" : ""
      }`}
    >
      <div className="form-popup-zone-closer"></div>
      <div className="form_popup">
        <div className="form-popup-close-icon" onClick={() => closePopup()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1 1L20.9999 20.9999"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.00016 21L21 1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="contact-form-right">
          <p className="form-popup-text">Connect web 3.0 wallet</p>
          <p className="form-popup-description">
            Choose the network you will be interacting with
          </p>
          <div className="popup-column popup-column-margin">
            {networkList.map((network) => (
              <div
                className="popup-border-box"
                key={"connect_" + network.chainId}
              >
                <div className="popup-border-box-row popup-border-box-row1">
                  <div className="header-wallet-img">
                    <img src="assets/img/custom2/image 1.svg" alt="" />
                    <div className="header-wallet-currency-icon">
                      <img src={network.topMenuLogo} alt="" />
                    </div>
                  </div>
                  <div className="popup-border-text-cnt">
                    <div className="popup-border-text">Metamask</div>
                    <div className="popup-border-text2">
                      {network.name} network
                    </div>
                  </div>
                </div>
                <div className="popup-border-box-row popup-border-box-row2">
                  {walletInfo.networkChainId != network.chainId && (
                    <>
                      <a
                        className="primary-button"
                        onClick={() => onChainChange(network)}
                      >
                        <div className="">Connect wallet</div>
                      </a>
                      <a
                        className="secondary-button"
                        onClick={() => addChain(network)}
                      >
                        <div className="">Add network</div>
                      </a>
                    </>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
