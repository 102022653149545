import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../features/walletService/mobileMenuService";
import TopWalletButton from "./WalletConnectButton/WalletConnectButton";

export default function MobileMenu() {
  let dispatch = useDispatch();
  let isMobMenuOpened = useSelector((state) => state.mobileMenuProvide.show);
  return (
    <div className={"mobile-menu " + (isMobMenuOpened ? "active" : "")}>
      <div className="mobile-menu-container">
        <div className="container header-container">
          <div className="cw-header__wrap view-mob">
            <div className="header-left-logo-text">
              <div className="header-logo">
                <a href="/">
                  <img src="assets/img/logo/logo.svg" alt="" />
                </a>
              </div>
            </div>
            <div
              className={
                "menu-button menu-button-close" +
                (isMobMenuOpened ? "active" : "")
              }
              onClick={() => dispatch(toggleMenu(false))}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M1 1L20.9999 20.9999"
                  stroke="white"
                  strokeWidth={"2"}
                  // strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00016 21L21 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div className="footer-row">
          <a href="https://asterizm.io/" target="_blank" rel="noreferrer">
            Asterizm website
          </a>
          <div className="menu-delimeter"></div>
          <a
            href="https://asterizm.io/wp-content/themes/crystal-wallet/assets/pdf/Asterizm%20White%20Paper%20Nov%202022.pdf"
            target="_blank"
            rel="noreferrer"
          >
            White paper
          </a>
          <div className="menu-delimeter"></div>
        </div>
        <div className="footer-social-cnt">
          <a
            className="footer-social-item"
            href="https://twitter.com/Asterizm_layer"
            target="_blank"
            rel="noreferrer"
          >
            <img src="assets/img/custom2/twitter-img.svg" alt="" />
          </a>
          <a
            className="footer-social-item"
            href="https://t.me/debridges"
            target="_blank"
            rel="noreferrer"
          >
            <img src="assets/img/custom2/iconmonstr-telegram-4 1.svg" alt="" />
          </a>
        </div>
      </div>

      <TopWalletButton
        customClass={"primary-button-section1 mobile-menu-button-1"}
      />
    </div>
  );
}
