import { Contract, ethers, utils } from "ethers";
import { IS_DEV } from "../../constants/SystemConstants";
import { showErrorDialog } from "../../features/errorService";
import { isZeroAddress } from "../../features/walletService/utils";
// import { setError } from "../../features/walletService/walletService";
import ERC20Abi from "../../utils/ERC20Abi";
import { getNetworks } from "../../utils/Networkutil";

export const MetamaskWebProvider = {
  ethereum: window.ethereum,
  isConnected: function () {
    return this.ethereum ? this.ethereum.isConnected() : false;
  },
  autoConnect: async function (dispatch, walletInfo) {
    if (!this.ethereum) {
      dispatch(showErrorDialog("Extension Metamask is not installed"));
      return walletInfo;
    }
    const accounts = await window.ethereum.request({
      method: "eth_accounts",
    });
    if (accounts.length > 0) {
      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = this.ethereum.networkVersion;
      walletInfo.isConnected = true;
    }
    return walletInfo;
  },
  connect: async function (dispatch, walletInfo) {
    if (!this.isConnected()) {
      console.log("error");
      dispatch(showErrorDialog("Extension Metamask is not installed"));
      return walletInfo;
    }
    try {
      let accounts = await this.ethereum.request({
        method: "eth_requestAccounts",
      });
      walletInfo.accountAddress = accounts[0];
      walletInfo.networkChainId = this.ethereum.networkVersion;
      walletInfo.isConnected = true;
    } catch (error) {
      console.log(error, "error");
      dispatch(showErrorDialog(error.message));
    }
    console.log(walletInfo, "walletInfo connect");
    return walletInfo;
  },
  getNativeBalance: async function (address) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    console.log(address, "address");
    let bal = await provider.getBalance(address);
    return parseFloat(ethers.utils.formatEther(bal)).toFixed(4);
  },
  requestApprove: async function (tokenAddress, accountAddress, amount) {
    let abi = [
      "function approve(address _spender, uint256 _value) public returns (bool success)",
    ];
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    // let provider = ethers.getDefaultProvider('ropsten')
    let contract = new ethers.Contract(tokenAddress, abi, provider);
    const res = await contract.approve(accountAddress, amount);
    console.log(res, "res");
    return res;
  },
  getBalance: async function (token, walletInfo, network) {
    return isZeroAddress(token.contractAddress)
      ? this.getNativeBalance(walletInfo.accountAddress)
      : this.getTokenBalance(token, walletInfo);
  },
  getTokenBalance: async function (token, walletInfo) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const contract = new Contract(token.contractAddress, ERC20Abi, provider);
    const balance = await contract.balanceOf(walletInfo.accountAddress);
    console.log(balance, "balance");
    return utils.formatUnits(balance, token.decimals);
    // console.log(res, 'res')
  },
  addChain: async function (selectedNetwork, dispatch) {
    let isAdded = false;
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      isAdded = true;
    } catch (error) {
      dispatch(showErrorDialog(error.message));
    }
    return isAdded;
  },
  addChainById: async function (networkChainId, dispatch) {
    const network = getNetworks().find((v) => v.chainId == networkChainId);
    let selectedNetwork = this.makeAddNetwork(network);
    let isAdded = false;
    try {
      await window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [selectedNetwork],
      });
      isAdded = true;
    } catch (error) {
      dispatch(showErrorDialog(error.message));
    }
    return isAdded;
  },
  makeAddNetwork: function (network) {
    return {
      chainId: ethers.utils.hexValue(parseInt(network.chainId)),
      chainName: network.chainName,
      nativeCurrency: {
        name: network.nativeCurrency.name,
        decimals: network.nativeCurrency.decimals,
        symbol: network.nativeCurrency.symbol,
      },
      rpcUrls: network.rpcUrls,
      blockExplorerUrls: network.blockExplorerUrls,
    };
  },
  changeChain: async function (walletInfo, dispatch, network) {
    let isChanged = false;
    let selectedNetwork = this.makeAddNetwork(network);
    console.log(walletInfo.isConnected, selectedNetwork, "selectedNetwork");
    // if (!walletInfo.isConnected) {
    //   walletInfo = await this.connect(walletInfo, dispatch);
    // }
    // console.log(
    //   this.ethereum.networkVersion,
    //   walletInfo.chainId,
    //   walletInfo,
    //   "walletInfo"
    // );
    if (this.ethereum.networkVersion !== walletInfo.chainId) {
      try {
        await this.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: selectedNetwork.chainId }],
          // params: [{ chainId: utils.toHex(chainId) }],
        });
        isChanged = true;
      } catch (err) {
        console.log(err, "err");
        if (err.code == 4902) {
          isChanged = this.addChain(selectedNetwork, dispatch);
        } else {
          dispatch(showErrorDialog(err.message));
        }
      }
    }
    return isChanged;
  },
  calcGas: async function () {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const price = await provider.getGasPrice();
    const str = ethers.utils.formatEther(price);
    const eth = str * 2;
    const estimation = ethers.utils.parseEther(eth.toFixed(18));
    // console.log(price,str,eth,estimation,'price,str,eth,estimation')
    return estimation._hex;
  },
  calsTransGas: async function (transactionData) {
    const provider = new ethers.providers.Web3Provider(this.ethereum);
    const price = await provider.estimateGas({
      to: transactionData.to,

      // `function deposit() payable`
      data: transactionData.data,

      // 1 ether
      // value: parseEther("1.0")
    });
    return price;
  },
  sendTransaction: async function (transactionData, dispatch) {
    dispatch(showErrorDialog(null));
    let hasError = true;
    let txHash = null;

    const gasPrice = transactionData.gasPrice
      ? transactionData.gasPrice
      : await this.calcGas();

    // const gas = transactionData.gasLimit
    //   ? transactionData.gasLimit
    //   : await this.calsTransGas(transactionData);

    // console.log(gasPrice,'gasPrice')
    console.log(
      transactionData.value,
      parseInt(transactionData.value),
      "transactionData.value"
    );
    const transactionParameters = {
      nonce: "0x00", // ignored by MetaMask
      gasPrice: gasPrice, //transactionData.gasPrice ? transactionData.gasPrice : '0x09184e72a000', // customizable by user during MetaMask confirmation.
      gas: transactionData.gasLimit, // ?  transactionData.gasLimit : '0x5208', // customizable by user during MetaMask confirmation.
      to: transactionData.to, // Required except during contract publications.
      from: this.ethereum.selectedAddress, // must match user's active address.
      value: transactionData.value, // Only required to send ether to the recipient from the initiating external account.
      data: transactionData.data, // Optional, but used for defining smart contract creation and interaction.
      chainId: transactionData.chainId, // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
    };
    console.log(transactionParameters, "transactionParameters");
    try {
      txHash = await this.ethereum.request({
        method: "eth_sendTransaction",
        params: [transactionParameters],
      });
      hasError = false;
      console.log(txHash, "txHash");
    } catch (error) {
      dispatch(showErrorDialog(error.message));
      console.log(error, "error");
    }
    return {
      hasError: hasError,
      txHash: txHash,
    };
  },
};
