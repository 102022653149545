import { BigNumber, ethers, utils } from "ethers";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SliderInput from "../../components/SliderInput";
import TransactionStepPopup, {
  TYPE_TRANSFER,
  TYPE_TRANSFER_CLAIM,
  TYPE_TRANSFER_FINISHED,
  TYPE_TRANSFER_REFUSED,
} from "../../components/TransactionStepPopup";
import { TICK_MAX, TOKEN_NAME } from "../../constants/SystemConstants";
import { networkSelectDialogToggle } from "../../features/walletService/walletService";
import TopWalletButton from "../../layout/WalletConnectButton/WalletConnectButton";
import { getNetworks } from "../../utils/Networkutil";

export default function Home() {
  const [networkList, setNetworkList] = useState([]);
  let dispatch = useDispatch();

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  useEffect(() => {
    getNetworks().forEach((v) => {
      v.val = TICK_MAX / getNetworks().length;
    });
    setNetworkList(getNetworks());
    console.log(walletInfo.networkChainId, walletInfo.networkChainId);
    // if (walletInfo.networkChainId) listenNetwork(walletInfo.networkChainId);
  }, []);

  const openNetworkToggle = () => {
    dispatch(networkSelectDialogToggle());
  };

  const clearNetworkVals = () => {
    setNetworkList((prevState) => {
      const newState = prevState.map((network) => {
        network.val = 0;
        network.hash = null;
        return network;
      });

      return newState;
    });
  };

  const changeNetworkVal = (curNetwork, val) => {
    // const valForOthers = (TICK_MAX - val) / (networkList.length - 1);
    setNetworkList((prevState) => {
      const newState = prevState.map((network) => {
        if (network.chainId === curNetwork.chainId) {
          return { ...network, val: val };
        }
        return network;
      });

      return newState;
    });
  };

  const setNetworkHash = (chainId, hash) => {
    // const valForOthers = (TICK_MAX - val) / (networkList.length - 1);
    setNetworkList((prevState) => {
      const newState = prevState.map((network) => {
        console.log(
          network.chainId == chainId,
          network.chainId,
          chainId,
          "network.chainId == chainId"
        );
        if (network.chainId == chainId) {
          return { ...network, hash: hash };
        }
        return network;
      });

      return newState;
    });
  };

  const backImage = () => {
    return (
      getNetworks().find((v) => v.chainId == walletInfo.networkChainId)
        ?.backGround || "assets/img/custom2/Frame (1).png"
    );
  };

  const allAmount = useMemo(() => {
    return networkList.reduce((sum, v) => (sum += parseInt(v.val)), 0);
  }, [networkList]);

  const isNeedChangeNetwork = useMemo(() => {
    return (
      networkList.findIndex((v) => v.chainId == walletInfo.networkChainId) == -1
    );
  }, [networkList, walletInfo.networkChainId]);

  const [popupInfo, setPopupInfo] = useState({
    show: false,
    type: null,
  });

  const showStepPopup = (type, isShow = true) => {
    setPopupInfo({
      show: isShow,
      type: type,
    });
  };

  const showClaim = () => {
    setPopupInfo({
      show: true,
      type: TYPE_TRANSFER_CLAIM,
      networkData: networkList,
    });
  };

  const abi = [
    "function claim(uint16[] memory _chainIds, uint[] memory _amounts, address[] memory _tokenAddresses, uint length) public",
  ];

  const claimStart = async () => {
    const ethereum = window.ethereum;
    const accounts = await ethereum.request({
      method: "eth_requestAccounts",
    });

    const provider = new ethers.providers.Web3Provider(ethereum);
    const walletAddress = accounts[0]; // first account in MetaMask
    const signer = provider.getSigner(walletAddress);

    let currentNetwork = networkList.find(
      (v) => v.chainId == walletInfo.networkChainId
    );
    console.log(
      currentNetwork.claimContractAddress,
      "currentNetwork.claimContractAddress"
    );
    const claimContract = new ethers.Contract(
      currentNetwork.claimContractAddress,
      abi,
      signer
    );
    try {
      let chainIds = networkList.map((v) => v.sendChainId);
      const chainVals = networkList.map((v) =>
        ethers.utils.parseUnits(v.val.toString(), v.nativeCurrency.decimals)
      );

      // TODO: Move that to constants
      // That list is required to pass token address at the destination network
      const tokenAddresses = networkList.map((v) => v.claimTokenAddress);
      /*[
        "0xCae40C1Ad0A401cc3Fc20cE3e28ac768FA422161",
        "0x1679467004A2C0CD2FCF07580fE483E20bc9E7ac",
        "0x5B732fE1565775a1404186f9F57A8b8F5fabDd64",
      ];*/

      console.log("chainIds", chainIds);
      console.log("chainVals", chainVals);
      console.log("tokenAddresses", tokenAddresses);
      console.log("networkList.length", networkList.length);
      const tx = await claimContract.claim(
        chainIds,
        chainVals,
        tokenAddresses,
        networkList.length
      );
      setPopupInfo({
        show: true,
        type: TYPE_TRANSFER,
        networkList: networkList,
      });

      console.log(
        walletInfo.networkChainId,
        tx.hash,
        "walletInfo.networkChainId, tx.hash"
      );

      setNetworkHash(walletInfo.networkChainId, tx.hash);

      // networkList.forEach((v) => {
      //   if (v.chainId != walletInfo.networkChainId) {
      //     listenNetwork(v.chainId);
      //   }
      // });

      const receipt = await tx.wait();
      console.log(tx, receipt, "isClaimed");

      setPopupInfo({
        show: true,
        type: TYPE_TRANSFER_FINISHED,
        networkList: networkList,
      });
    } catch (error) {
      console.log(error, "error");
      showStepPopup(TYPE_TRANSFER_REFUSED);
    }
  };

  return (
    <div className="background-section top-content">
      <div className="background-section background-section-1-full-width">
        <div
          className="container section1-bcg"
          style={{ position: "relative" }}
        >
          <div className="anchor-section" id="sec1"></div>
          <div className="section1-content">
            <h1 className="showcase-heading-title">
              Empower your dApp <br className="view-mob" />
              with any cross-chain
              <br className="view-desc" /> logic
              <br className="view-mob" /> built on trustless and
              <br className="view-mob" /> secure <br className="view-desc" />
              Asterizm Layer!
            </h1>
            <div className="text-description">
              This is a showcase dApp to demonstrate
              <br className="view-mob" /> potential of the Asterizm trustless
              <br className="view-mob" /> infrastructure
              <br className="view-desc" />
              to build any cross-chain
              <br className="view-mob" /> logic for your protocol.
            </div>
            <TopWalletButton customClass={"view-mob"} />

            {/* <a className="primary-button primary-button-section1 view-mob">
              <div className="">Connect wallet</div>
            </a> */}
          </div>
        </div>
      </div>
      <div className="section-showcase-background-image">
        <img
          className="section-showcase-background-status section-showcase-background-image1 active"
          src={backImage()}
          alt=""
        />
      </div>

      <div className="background-section background-section-2-full-width">
        <div className="container section-showcase-bcg">
          <div className="section-2-border-block-cnt">
            <div className="section-showcase_cnt-block-drop"></div>
            <div className="showcase_cnt">
              <div className="showcase-mg showcase-description">
                Showcase for cross-chain:
              </div>
              <div className="showcase-button-block">
                <a className="primary-button">
                  <div className="">Claiming/emission</div>
                </a>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Token bridge</div>
                </div>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Messenger</div>
                </div>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Lending</div>
                </div>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Swaps</div>
                </div>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Farming</div>
                </div>
                <div className="primary-button disabled">
                  <div className="top-alert-dot">
                    <div className="top-alert">SOON</div>
                  </div>
                  <div className="">Privacy data transfers</div>
                </div>
              </div>
              <div className="showcase-heading">
                <div className="showcase-heading-text">
                  Try the cross-chain token
                  <br className="view-mob" /> claiming-emission
                  <br className="view-desc" />
                  to feel
                  <br className="view-mob" /> Asterizm opportunities
                </div>
                <div className="showcase-heading-title">1,200 {TOKEN_NAME}</div>
                <div>
                You can claim {TICK_MAX} test tokens with single transaction, first distributing them across 3 networks:
                </div>
              </div>
              <div className="showcase_form-container">
                <form className="showcase_form-cnt">
                  {networkList.map((network) => (
                    <div className="showcase_form-block" key={network.chainId}>
                      <div className="showcase_form-row">
                        <div className="showcase_form-icon">
                          <img src={network.logo} alt="" />
                        </div>
                        <div className="showcase_form-text-cnt">
                          <div className="showcase_form-text1">NETWORK</div>
                          <div className="showcase_form-text2">
                            {network.name}
                          </div>
                        </div>
                      </div>
                      <div className="showcase_form-border-line"></div>
                      <div className="showcase_form-input-text">
                        Numbers of tokens
                      </div>
                      <SliderInput
                        network={network}
                        onValChange={(val) => {
                          changeNetworkVal(network, val);
                        }}
                      />
                    </div>
                  ))}
                </form>
                {!walletInfo.isConnected && (
                  <a
                    className="primary-button"
                    onClick={() => openNetworkToggle()}
                  >
                    <div className="">Connect wallet</div>
                  </a>
                )}
                {walletInfo.isConnected && isNeedChangeNetwork && (
                  <a
                    className="primary-button"
                    onClick={() => openNetworkToggle()}
                  >
                    <div className="">Change network</div>
                  </a>
                )}
                {walletInfo.isConnected &&
                  !isNeedChangeNetwork &&
                  allAmount == TICK_MAX && (
                    <a className="primary-button" onClick={() => showClaim()}>
                      <div className="">Claim</div>
                    </a>
                  )}

                {allAmount != TICK_MAX && (
                  <div className="showcase-form-error">
                    <div className="showcase-form-error-icon">
                      <img src="assets/img/custom2/icons/error.svg" alt="" />
                    </div>
                    <div className="showcase-form-error-title">Error</div>
                    <div className="showcase-form-error-description">
                    The amount of tokens in 3 forms should be exactly 1200
                      {/* Текущая сумма {allAmount} */}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="container section2-bcg">
          <div className="core-con-container">
            <h2 className="showcase-heading-title">Core concept of Asterizm</h2>
            <div className="text-description">
            Asterizm is an enterprise  {" "}
            <br className="view-mob" />
            application-focused {" "}
              
            <br className="view-mob" />
              trustless
              <br className="view-desc" /> cross-chain communication {" "}
              <br className="view-mob" />
              solution binding L1&L2 networks.
              <br />
              <br />
              We provide a low-level communication {" "}
              <br className="view-mob" />
              primitive with trustless and secure
              <br className="view-desc" /> approach implemented by {" "}
              <br className="view-mob" />
              <b>encrypting the transmitted data</b> {" "}
              <br className="view-mob" />
              <br className="view-desc" />
              on the client application server side.
              <br />
            </div>
          </div>
        </div>
        <div className="container section4-bcg">
          <div className="section-2-border-block-cnt">
            <div className="section-2-border-block-drop"></div>
            <div className="section-2-border-block section-4-border-block view-desc">
              <img src="assets/img/custom2/Group 1000002556.svg" alt="" />
            </div>
            <div className="section-2-border-block section-4-border-block view-mob">
              <img src="assets/img/custom2/Group 1000002595.svg" alt="" />
            </div>
          </div>
        </div>
        <div className="container section4-bcg">
          <div className="section-2-border-block-cnt">
            <div className="section-2-border-block-drop"></div>
            <div className="section9-social-containeer">
              <div className="section9-social-bcg">
                <img
                  className="view-desc"
                  src="assets/img/custom2/Group 1000002523.svg"
                  alt=""
                />
                <img
                  className="view-mob"
                  src="assets/img/custom2/Group 1000002581.svg"
                  alt=""
                />
              </div>
              <div className="section9-social-content-cnt">
                <div className="section9-social-content">
                  <div className="showcase-heading-title">
                    Join our community
                  </div>
                </div>
                <div className="section9-social-row">
                  <a href="#" className="section9-social-row-item">
                    <div className="section9-social-row-item-bcg">
                      <img
                        src="assets/img/custom2/Frame 1000001044.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-row">
                      <img
                        src="assets/img/custom2/iconmonstr-discord-3 1 1.svg"
                        alt=""
                      />
                      <img
                        src="assets/img/custom2/arrows_arrow-right-up-open-in-new-apple-b-a 2.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-column">
                      <div className="section9-social-row-item-title">
                        Discord
                      </div>
                      <div className="section9-social-row-item-description">
                        @asterizm
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://twitter.com/Asterizm_layer"
                    target="_blank"
                    className="section9-social-row-item"
                    rel="noreferrer"
                  >
                    <div className="section9-social-row-item-bcg">
                      <img
                        src="assets/img/custom2/Frame 1000001044.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-row">
                      <img
                        src="assets/img/custom2/icons/iconmonstr-twitter-3 2.svg"
                        alt=""
                      />
                      <img
                        src="assets/img/custom2/arrows_arrow-right-up-open-in-new-apple-b-a 2.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-column">
                      <div className="section9-social-row-item-title">
                        Twitter
                      </div>
                      <div className="section9-social-row-item-description">
                        @asterizm
                      </div>
                    </div>
                  </a>
                  <a
                    href="https://t.me/debridges"
                    target="_blank"
                    className="section9-social-row-item"
                    rel="noreferrer"
                  >
                    <div className="section9-social-row-item-bcg">
                      <img
                        src="assets/img/custom2/Frame 1000001044.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-row">
                      <img
                        src="assets/img/custom2/icons/telegram-3.svg"
                        alt=""
                      />
                      <img
                        src="assets/img/custom2/arrows_arrow-right-up-open-in-new-apple-b-a 2.svg"
                        alt=""
                      />
                    </div>
                    <div className="section9-social-row-item-column">
                      <div className="section9-social-row-item-title">
                        Telegram
                      </div>
                      <div className="section9-social-row-item-description">
                        @asterizm
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <TransactionStepPopup
        popupInfo={popupInfo}
        networkList={networkList}
        closePopup={(p) => {
          showStepPopup(null, false);
          if (popupInfo.type == TYPE_TRANSFER_CLAIM && p) {
            claimStart();
          }
        }}
      />
    </div>
  );
}
