import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
};

export const mobileMenuService = createSlice({
  name: "mobileMenu",
  initialState,
  reducers: {
    toggleMenu: (state, action) => {
      state.show = action.payload;
      let element = document.getElementById("gototop");
      if (state.show) {
        element.classList.add("scroll-stop");
      } else {
        element.classList.remove("scroll-stop");
      }
      console.log(state, state.show, action, "action");
    },
  },
});

// Action creators are generated for each case reducer function
export const { toggleMenu } = mobileMenuService.actions;

export default mobileMenuService.reducer;
