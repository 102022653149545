import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import routes from "./routes/routes";
import Layout from "./layout/Layout";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  autoConnectWallet,
  networkChanged,
} from "./features/walletService/walletService";

function App() {
  let dispatch = useDispatch();

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (_chainId) => {
        dispatch(networkChanged(_chainId));
        console.log(_chainId, "_chainId");
      });
      // window.ethereum.on('disconnect', () => {
      //   window.location.reload();
      // })
    }
  }, []);

  useEffect(() => {
    // setTimeout(() => {
    dispatch(autoConnectWallet());
    // }, 100);
  }, [dispatch]);

  return (
    <BrowserRouter>
      <Routes>
        <Route element={<Layout />}>
          {routes.map((route, key) => (
            <Route key={key} path={route.path} element={<route.element />} />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
