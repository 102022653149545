import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import ChangeNetworkPopup from "../components/ChangeNetworkPopup";
import ErrorPopup from "../components/ErrorPopup";
import Footer from "./Footer";
import MobileMenu from "./MobileMenu";
import TopMenu from "./TopMenu";

export default function Layout() {
  let isMobMenuOpened = useSelector((state) => state.mobileMenuProvide.show);
  return (
    <>
      <div className="sticky-zone">
        <a href="#gototop" className="to-top-button"></a>
      </div>
      <TopMenu />
      <MobileMenu />
      <Outlet />
      <ChangeNetworkPopup />
      <ErrorPopup />
      <Footer />
    </>
  );
}
