import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TICK_MAX, TOKEN_NAME } from "../constants/SystemConstants";
import { MetamaskWebProvider } from "../services/metamask/MetamaskWebProvider";
import { getNetworks } from "../utils/Networkutil";
import BouncingDotsLoader from "./BouncingDotsLoader";

export const TYPE_TRANSFER_FINISHED = 2;
export const TYPE_TRANSFER_REFUSED = 3;
export const TYPE_TRANSFER_CLAIM = 4;
export const TYPE_TRANSFER = 5;

export default function TransactionStepPopup({
  popupInfo,
  closePopup,
  networkList,
}) {
  // const [networkList, setNetworkList] = useState([]);

  let walletInfo = useSelector(
    (state) => state.walletServiceProvider.walletInfo
  );

  const dispatch = useDispatch();

  // useEffect(() => {
  //   setNetworkList(getNetworks());
  // }, []);

  const handleChainChange = async (network) => {
    if (walletInfo.networkChainId != network.chainId) {
      let isChanged = await MetamaskWebProvider.changeChain(
        walletInfo,
        dispatch,
        network
      );
      console.log(walletInfo, isChanged, "isChanged");
      if (isChanged) {
        closePopup();
      }
    }
  };

  const addChain = async (network) => {
    if (walletInfo.networkChainId != network.chainId) {
      let isAdded = await MetamaskWebProvider.addChainById(
        network.chainId,
        dispatch
      );
      console.log(walletInfo, isAdded, "isChanged");
      if (isAdded) {
        closePopup();
      }
    }
  };

  const curNetwork = networkList.find(
    (v) => v.chainId == walletInfo.networkChainId
  ) 

  return (
    <>
      {popupInfo.type === TYPE_TRANSFER_FINISHED && (
        <div
          className={`form_popup_cnt succesfull_popup ${
            popupInfo.show ? "active" : ""
          }`}
        >
          <div className="form-popup-zone-closer"></div>
          <div className="form_popup claim-popup">
            <div className="form-popup-close-icon" onClick={() => closePopup()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M1 1L20.9999 20.9999"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00016 21L21 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="contact-form-center">
              <div className="form-popup-text-great">GREAT!</div>
              <p className="form-popup-text">Claim succesfull</p>
              <p className="claim-popup-description">
                You’ve got{" "}
                <span className="form-popup-text-white">{TICK_MAX} {TOKEN_NAME}</span>
              </p>

              <div className="succesfull_popup-form">
                {networkList.map((network, index) => (
                  <div key={"network" + index}>
                    <div className="succesfull_popup-form-row">
                      <div className="succesfull_popup-form-row-row">
                        <div className="succesfull_popup-form-row-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                          >
                            <path
                              d="M15 28.4375C7.5875 28.4375 1.5625 22.4125 1.5625 15C1.5625 7.5875 7.5875 1.5625 15 1.5625C22.4125 1.5625 28.4375 7.5875 28.4375 15C28.4375 22.4125 22.4125 28.4375 15 28.4375ZM15 3.4375C8.625 3.4375 3.4375 8.625 3.4375 15C3.4375 21.375 8.625 26.5625 15 26.5625C21.375 26.5625 26.5625 21.375 26.5625 15C26.5625 8.625 21.375 3.4375 15 3.4375Z"
                              fill="#3CD65E"
                            />
                            <path
                              opacity="0.35"
                              d="M13.2258 19.475C12.9758 19.475 12.7383 19.375 12.5633 19.2L9.02578 15.6625C8.66328 15.3 8.66328 14.7 9.02578 14.3375C9.38828 13.975 9.98828 13.975 10.3508 14.3375L13.2258 17.2125L19.6508 10.7875C20.0133 10.425 20.6133 10.425 20.9758 10.7875C21.3383 11.15 21.3383 11.75 20.9758 12.1125L13.8883 19.2C13.7133 19.375 13.4758 19.475 13.2258 19.475Z"
                              fill="#3CD65E"
                            />
                          </svg>
                        </div>

                        <div className="succesfull_popup-form-row-column">
                          <div className="succesfull_popup-form-row-text">
                            {network.name} network
                          </div>
                          <div className="succesfull_popup-form-row-text2">
                            {network.val} Funds received
                          </div>
                        </div>
                      </div>

                      <a
                        href={
                          network.blockExplorerUrls[0] +
                          "/address/" +
                          walletInfo.accountAddress
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        <div className="succesfull_popup-form-row-link-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M13.7505 15.2087H12.4922C12.1505 15.2087 11.8672 14.9253 11.8672 14.5837C11.8672 14.242 12.1505 13.9587 12.4922 13.9587H13.7505C15.9339 13.9587 17.7089 12.1837 17.7089 10.0003C17.7089 7.81699 15.9339 6.04199 13.7505 6.04199H12.5005C12.1589 6.04199 11.8755 5.75866 11.8755 5.41699C11.8755 5.07533 12.1505 4.79199 12.5005 4.79199H13.7505C16.6255 4.79199 18.9589 7.12533 18.9589 10.0003C18.9589 12.8753 16.6255 15.2087 13.7505 15.2087Z"
                              fill="white"
                            />
                            <path
                              d="M7.5013 15.2087H6.2513C3.3763 15.2087 1.04297 12.8753 1.04297 10.0003C1.04297 7.12533 3.3763 4.79199 6.2513 4.79199H7.5013C7.84297 4.79199 8.1263 5.07533 8.1263 5.41699C8.1263 5.75866 7.84297 6.04199 7.5013 6.04199H6.2513C4.06797 6.04199 2.29297 7.81699 2.29297 10.0003C2.29297 12.1837 4.06797 13.9587 6.2513 13.9587H7.5013C7.84297 13.9587 8.1263 14.242 8.1263 14.5837C8.1263 14.9253 7.84297 15.2087 7.5013 15.2087Z"
                              fill="white"
                            />
                            <path
                              d="M13.3346 10.625H6.66797C6.3263 10.625 6.04297 10.3417 6.04297 10C6.04297 9.65833 6.3263 9.375 6.66797 9.375H13.3346C13.6763 9.375 13.9596 9.65833 13.9596 10C13.9596 10.3417 13.6763 10.625 13.3346 10.625Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </a>
                    </div>
                    {index < networkList.length - 1 && (
                      <div className="succesfull_popup-form-delimeter"></div>
                    )}
                  </div>
                ))}
                <br className="view-mob" />
                <br className="view-mob" />
                <a className="primary-button view-mob">
                  <div className="">Done</div>
                </a>
              </div>

              <a
                className="primary-button view-desc"
                onClick={() => closePopup()}
              >
                <div className="">Done</div>
              </a>
            </div>
          </div>
        </div>
      )}
      {popupInfo.type === TYPE_TRANSFER_REFUSED && (
        <div
          className={`form_popup_cnt rejected_popup ${
            popupInfo.show ? "active" : ""
          }`}
        >
          <div className="form-popup-zone-closer"></div>
          <div className="form_popup claim-popup">
            <div className="form-popup-close-icon" onClick={() => closePopup()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M1 1L20.9999 20.9999"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00016 21L21 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="contact-form-center">
              <p className="form-popup-text">Claim rejected</p>
              <div className="popup_claim-status-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="49"
                  viewBox="0 0 49 49"
                  fill="none"
                >
                  <path
                    d="M24.5007 44.9163C35.7298 44.9163 44.9173 35.7288 44.9173 24.4997C44.9173 13.2705 35.7298 4.08301 24.5007 4.08301C13.2715 4.08301 4.08398 13.2705 4.08398 24.4997C4.08398 35.7288 13.2715 44.9163 24.5007 44.9163Z"
                    stroke="#FF4443"
                    strokeWidth="3"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <g opacity="0.4">
                    <path
                      d="M18.7227 30.2775L30.2785 18.7217"
                      stroke="#FF4443"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M30.2785 30.2775L18.7227 18.7217"
                      stroke="#FF4443"
                      strokeWidth="3"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <p className="claim-popup-description">
                Something went wrong. Try again.
              </p>
              <a className="primary-button" onClick={() => closePopup()}>
                <div className="">Done</div>
              </a>
            </div>
          </div>
        </div>
      )}
      {popupInfo.type === TYPE_TRANSFER_CLAIM && (
        <div
          className={`form_popup_cnt confirmation_popup ${
            popupInfo.show ? "active" : ""
          }`}
        >
          <div className="form-popup-zone-closer"></div>
          <div className="form_popup confirmation-popup">
            <div className="form-popup-close-icon" onClick={() => closePopup()}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M1 1L20.9999 20.9999"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.00016 21L21 1"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="confirmation-popup-title">
              Transaction <br className="view-mob" />
              confirmation
            </div>
            <div className="contact-form-center">
              <div className="confirmation-popup-text confirmation-popup-margin">
                This dApp is designed to demonstrate the capabilities of
                Asterizm Layer in the development of any cross-chain logic.
              </div>
              <div className="confirmation-popup-border-block confirmation-popup-margin">
                <div className="confirmation-popup-text confirmation-popup-margin">
                  In this example, with a single transaction in the  {curNetwork ? curNetwork.name :''} {" "}
                  network, you can get tokens
                  <br />
                  to wallets in 3 networks at once in a proportion of your
                  choice:
                </div>
                <div className="confirmation-popup-border-block-row">
                  {popupInfo.networkData.map((v) => (
                    <div
                      className="confirmation-popup-border-block-item"
                      key={"block" + v.chainId}
                    >
                      <div className="confirmation-popup-border-block-item-icon">
                        <img
                          src="assets/img/custom2/icons/asterizm-icon.svg"
                          alt=""
                        />
                        <div className="header-wallet-currency-icon">
                          <img src={v.topMenuLogo} alt="" />
                        </div>
                      </div>
                      <div className="confirmation-popup-border-block-item-column">
                        <div className="confirmation-popup-border-block-item-text">
                          {v.val}
                        </div>
                        <div className="confirmation-popup-border-block-item-text2">
                          {TOKEN_NAME} on {v.name}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="confirmation-popup-heading confirmation-popup-margin">
                What happens after you click Claim?
              </div>
              <div className="confirmation-popup-img-block-border confirmation-popup-margin">
                <img
                  className="view-desc"
                  src={
                    networkList.find(
                      (v) => v.chainId == walletInfo.networkChainId
                    ).groupImg
                  }
                  alt=""
                />
                <img
                  className="view-mob"
                  src={
                    networkList.find(
                      (v) => v.chainId == walletInfo.networkChainId
                    ).mobGroupImg
                  }
                  alt=""
                />
              </div>
              <div className="confirmation-popup-text confirmation-popup-gray-text">
                1. Your transaction will be processed in the {curNetwork ? curNetwork.name :''} network by
                initiating an encrypted cross-chain message
                <br className="view-desc" />
                with instructions to issue and send {TOKEN_NAME} tokens to your
                addresses in the {networkList.filter( (v)=> v.chainId != walletInfo.networkChainId )[0].name } network and {networkList.filter( (v)=> v.chainId != walletInfo.networkChainId )[1].name } network
                <br className="view-desc" />
                using the Asterizm Connector, and sending {TOKEN_NAME} tokens in the
                {curNetwork ? curNetwork.name :''} network to your address.
              </div>
              <div className="confirmation-popup-separator"></div>
              <div className="confirmation-popup-text confirmation-popup-gray-text">
                2. The cross-chain message will first be encrypted on the Client
                Server (in this case the Asterizm server)
                <br className="view-desc" />
                and then transmitted in encrypted form to other networks using
                the Asterizm Relayer Serve
              </div>
              <div className="confirmation-popup-separator"></div>
              <div className="confirmation-popup-text confirmation-popup-gray-text">
                3. The execution of the message will result in {TOKEN_NAME} tokens
                being issued and sent to the transaction initiator's
                <br className="view-desc" />
                "Claim" address on the {curNetwork ? curNetwork.name :''} network. The address is passed as
                a parameter in the cross-chain message.
              </div>
              <div className="confirmation-popup-separator"></div>
              <div className="confirmation-popup-text confirmation-popup-gray-text confirmation-popup-margin">
                3. The execution of the message will result in {TOKEN_NAME} tokens
                being issued and sent to the transaction initiator's
                <br className="view-desc" />
                "Claim" address on the {curNetwork ? curNetwork.name :''}  network. The address is passed as
                a parameter in the cross-chain message.
              </div>

              <div className="confirmation-popup-heading confirmation-popup-margin">
                Click on the "Claim" button to make a single transaction to
                receive tokens <br className="view-desc" />
                across multiple networks at once.
              </div>
              <div className="confirmation-popup-text confirmation-popup-gray-text confirmation-popup-margin">
                The possibilities of the Asterizm infrastructure in designing
                and implementing cross-chain operations are almost
                <br className="view-desc" />
                limitless, join the community and build your cross-chain
                applications with a trusstless and secure solution.
              </div>

              <a
                className="primary-button"
                onClick={() => closePopup(true)}
                style={{ position: "absolute", bottom: "-10px" }}
              >
                <div className="">Claim</div>
              </a>
            </div>
          </div>
        </div>
      )}
      {popupInfo.type === TYPE_TRANSFER && (
        <>
          <div
            className={`form_popup_cnt confirmation_popup ${
              popupInfo.show ? "active" : ""
            }`}
          >
            <div className="form-popup-zone-closer"></div>
            <div className="form_popup claim-popup">
              <div
                className="form-popup-close-icon"
                onClick={() => closePopup()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M1 1L20.9999 20.9999"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M1.00016 21L21 1"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="contact-form-center">
                <div className="form-popup-text-great">GREAT!</div>
                <p className="form-popup-text">Claim process started</p>
                <p className="claim-popup-description">
                  Please wait 5-20 minutes for the funds to arrive
                  <br />
                  at your wallet
                </p>

                {/* <div className="succesfull_popup-form">
                  <br className="view-mob" />
                  <br className="view-mob" />
                  <a className="primary-button view-mob">
                    <div className="">Done</div>
                  </a>
                </div>

                <a
                  className="primary-button view-desc"
                  onClick={() => closePopup()}
                >
                  <div className="">Done</div>
                </a> */}
                <BouncingDotsLoader />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
