import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleMenu } from "../features/walletService/mobileMenuService";
import TopWalletButton from "./WalletConnectButton/WalletConnectButton";

export default function TopMenu() {
  let dispatch = useDispatch();
  let isMobMenuOpened = useSelector((state) => state.mobileMenuProvide.show);

  return (
    <div className="background-section header-background ">
      <div className="container header-container">
        <header className="header-content">
          <div className="cw-header__wrap view-desc">
            <div className="header-left-logo-text">
              <div className="header-logo">
                <a href="/">
                  <img src="assets/img/logo/logo.svg" alt="" />
                </a>
              </div>
              <div className="header-ver-line"></div>
              <div className="header-logo-text">
                Asterizm layer
                <br />
                showcase
              </div>
            </div>

            <div className="header-row">
              <a href="https://asterizm.io/" target="_blank" rel="noreferrer">
                Asterizm website
              </a>
              <div className="menu-delimeter"></div>
              <a
                href="https://asterizm.io/wp-content/themes/crystal-wallet/assets/pdf/Asterizm%20White%20Paper%20Nov%202022.pdf"
                target="_blank"
                rel="noreferrer"
              >
                White paper
              </a>
              <div className="menu-delimeter"></div>
              <a
                className="footer-social-item"
                href="https://twitter.com/Asterizm_layer"
                target="_blank"
                rel="noreferrer"
              >
                <img src="assets/img/custom2/twitter-img.svg" alt="" />
              </a>
              <a
                className="footer-social-item"
                href="https://t.me/debridges"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src="assets/img/custom2/iconmonstr-telegram-4 1.svg"
                  alt=""
                />
              </a>
            </div>
            <TopWalletButton />
            {/* <div className="header-connected-wallet">
                <div className="header-wallet-img">
                  <img src="assets/img/custom2/image 1.svg" alt="" />
                  <div className="header-wallet-currency-icon">
                    <img src="assets/img/custom2/Group 1000002558.svg" alt="" />
                  </div>
                </div>
                <div className="header-wallet-info">
                  <div className="header-wallet-name">0x07...B57F</div>
                  <div className="header-wallet-info-icon">
                    <img src="assets/img/custom2/Frame 1000001089.svg" alt="" />
                  </div>
                </div>
              </div> */}
          </div>

          <div className="cw-header__wrap view-mob">
            <div className="header-left-logo-text">
              <div className="header-logo">
                <a href="/">
                  <img src="assets/img/logo/logo.svg" alt="" />
                </a>
              </div>
            </div>
            <div
              className={
                "menu-button menu-button-open " +
                (isMobMenuOpened ? "active" : "")
              }
              onClick={() => dispatch(toggleMenu(true))}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="11"
                viewBox="0 0 20 11"
                fill="none"
              >
                <line
                  x1="20"
                  y1="0.5"
                  x2="4.37114e-08"
                  y2="0.500002"
                  stroke="white"
                />
                <line
                  x1="20"
                  y1="5.5"
                  x2="4.37114e-08"
                  y2="5.5"
                  stroke="white"
                />
                <line
                  x1="20"
                  y1="10.5"
                  x2="4.37114e-08"
                  y2="10.5"
                  stroke="white"
                />
              </svg>
            </div>
          </div>
        </header>
      </div>
    </div>
  );
}
