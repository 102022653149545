import { useDispatch, useSelector } from "react-redux";
import { closeErrorDialog } from "../features/errorService";

export default function ErrorPopup() {
  let showErrorPopup = useSelector(
    (state) => state.errorServiceProvider.showErrorPopup
  );
  let error = useSelector((state) => state.errorServiceProvider.error);

  const closePopup = () => {
    dispatch(closeErrorDialog());
  };

  const dispatch = useDispatch();

  return (
    <div
      className={`form_popup_cnt rejected_popup ${
        showErrorPopup ? "active" : ""
      }`}
    >
      <div className="form-popup-zone-closer"></div>
      <div className="form_popup claim-popup">
        <div className="form-popup-close-icon" onClick={() => closePopup()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              d="M1 1L20.9999 20.9999"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M1.00016 21L21 1"
              stroke="white"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
        <div className="contact-form-center">
          <p className="form-popup-text">Error</p>
          <div className="popup_claim-status-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="49"
              height="49"
              viewBox="0 0 49 49"
              fill="none"
            >
              <path
                d="M24.5007 44.9163C35.7298 44.9163 44.9173 35.7288 44.9173 24.4997C44.9173 13.2705 35.7298 4.08301 24.5007 4.08301C13.2715 4.08301 4.08398 13.2705 4.08398 24.4997C4.08398 35.7288 13.2715 44.9163 24.5007 44.9163Z"
                stroke="#FF4443"
                strokeWidth="3"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <g opacity="0.4">
                <path
                  d="M18.7227 30.2775L30.2785 18.7217"
                  stroke="#FF4443"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M30.2785 30.2775L18.7227 18.7217"
                  stroke="#FF4443"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
            </svg>
          </div>
          <p className="claim-popup-description">{error}</p>
          <a className="primary-button" onClick={() => closePopup()}>
            <div className="">Done</div>
          </a>
        </div>
      </div>
    </div>
  );
}
