import { useEffect, useMemo, useState } from "react";
import { TICK_STEP, TICK_MAX } from "../constants/SystemConstants";

export default function SliderInput({ network, onValChange }) {
  const [ticks, setTicks] = useState([]);
  // const [curVal, setVal] = useState(200);

  const rangeWidth = useMemo(() => {
    return network ? (network.val / TICK_MAX) * 100 : 0;
  }, [network]);

  useEffect(() => {
    let items = [];
    for (let index = 0; index <= TICK_MAX; index += TICK_STEP) {
      items.push(index);
    }
    setTicks(items);
  }, []);

  return (
    network && (
      <div className="showcase_form-input-cnt">
        <div className="showcase_form-input">
          <input
            type="number"
            name=""
            value={network.val}
            min="0"
            max={TICK_MAX}
            className="numb-input"
            onChange={(event) => {
              if (event.target.value <= TICK_MAX)
                onValChange(event.target.value);
            }}
          />

          <div className="max-button" id="maxButton1">
            MAX
          </div>
        </div>
        <div className="showcase_form-input-range">
          <div className="range">
            <input
              type="range"
              min="0"
              max={TICK_MAX}
              value={network.val}
              step="1"
              name=""
              className="slider"
              onInput={(event) => {
                onValChange(event.target.value);
              }}
              // oninput="updateTextInput1(this.value);"
            />
            <div className="range-ticks">
              {ticks.map((number, index) => (
                <div key={network.chainId + "_" + index}>
                  <span className="range-tick">
                    <span className="range-tick-text">{number}</span>
                  </span>
                  {ticks.length === index - 1 && (
                    <span className="range-tick"></span>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="showcase_form-input-range-background-cnt">
            <div
              className="showcase_form-input-range-background"
              id="range1bcg"
              style={{ width: rangeWidth + "%" }}
            ></div>
          </div>
        </div>
      </div>
    )
  );
}
