import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <>
        <footer className="footer">
          <div className="container footer-container">
            <div className="footer-content">
              <div className="footer-column">
                <div className="header-logo">
                  <a href="https://<?=$_SERVER['HTTP_HOST']?><?=$_SERVER['REQUEST_URI']?>">
                    <img src="assets/img/logo/logo.svg" alt="" />
                  </a>
                </div>
              </div>
              <div className="footer-social-cnt">
                <a
                  className="footer-social-item"
                  href="https://twitter.com/Asterizm_layer"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src="assets/img/custom2/twitter-img.svg" alt="" />
                </a>
                <a
                  className="footer-social-item"
                  href="https://t.me/debridges"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="assets/img/custom2/iconmonstr-telegram-4 1.svg"
                    alt=""
                  />
                </a>
                <a className="footer-social-item" href="/">
                  <img
                    src="assets/img/custom2/iconmonstr-medium-4 1.svg"
                    alt=""
                  />
                </a>
              </div>
              <div className="footer-row">
                <a href="https://asterizm.io/" target="_blank" rel="noreferrer">
                  Asterizm website
                </a>
                <div className="menu-delimeter"></div>
                <a
                  href="https://asterizm.io/wp-content/themes/crystal-wallet/assets/pdf/Asterizm%20White%20Paper%20Nov%202022.pdf"
                  target="_blank"
                  rel="noreferrer"
                >
                  White paper
                </a>
                <div className="menu-delimeter"></div>
                <a
                  className="footer-border-block"
                  href="https://app.chainspot.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <div className="footer-border-block-img">
                    <img src="assets/img/Chainspot-Router.svg" alt="" />
                  </div>
                </a>
              </div>

              <div className="footer-first-cnt-buttons">
                <p className="footer-copyright ">© {(new Date).getFullYear()} Asterizm Protocol.</p>
              </div>
            </div>
          </div>

          {/* <div className="">
            <p className="form-popup-toggle">Connect popup-toggle</p>
            <p className="succesfull-popup-toggle">succesfull-popup-toggle</p>
            <p className="rejected-popup-toggle">rejected-popup-toggle</p>
            <p className="confirmation-popup-toggle">
              Transaction confirmation popup
            </p>
          </div> */}
        </footer>
      </>
    );
  }
}
export default Footer;
