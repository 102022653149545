import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MetamaskWebProvider } from "../../services/metamask/MetamaskWebProvider";
import { localStorageObj } from "../localstorage";

export const PROVIDER_METAMASK = "metamask";
export const PROVIDER_EVERWALLET = "everWallet";

export const getWalletAmount = async (walletInfo, formData) => {
  let balance = 0;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      balance = await MetamaskWebProvider.getBalance(
        formData.cryptoFrom,
        walletInfo,
        formData.routeFrom
      );
      break;
    default:
      break;
  }
  return balance;
};

export const sendWalletTransaction = async (
  walletInfo,
  transactionData,
  dispatch
) => {
  let transData = null;
  switch (walletInfo.providerType) {
    case PROVIDER_METAMASK:
      transData = await MetamaskWebProvider.sendTransaction(
        transactionData,
        dispatch
      );
      break;
    default:
      break;
  }
  return transData;
};

export const autoConnectWallet = createAsyncThunk(
  "autoconnect/wallet",
  async (__, { dispatch }) => {
    let providerType = localStorageObj.get("providerType");

    let walletInfo = { ...initialState.walletInfo };
    if (providerType == null) {
      return walletInfo;
    }

    if (providerType === PROVIDER_METAMASK) {
      walletInfo = await MetamaskWebProvider.autoConnect(dispatch, walletInfo);
    }
    walletInfo.providerType = providerType;
    dispatch(setWallet(walletInfo));
  }
);

export const connectWallet = createAsyncThunk(
  "connect/wallet",
  async (providerType = null, { dispatch, getState }) => {
    if (!providerType) {
      providerType = localStorageObj.get("providerType");
      let isConnected = true;
      if (providerType) {
        isConnected = MetamaskWebProvider.isConnected();
        //  await (providerType == PROVIDER_METAMASK
        //   ? MetamaskWebProvider.isConnected()
        //   : EverWalletWebProvider.isConnected());
      }
      console.log(providerType, isConnected, "providerType in storage");
      if (!providerType || !isConnected) {
        localStorageObj.remove("providerType");
        return null;
      }
    }
    const state = getState();
    let walletInfo = {
      accountAddress: null,
      balance: null,
      isConnected: false,
      providerType: providerType,
    };

    if (providerType === PROVIDER_METAMASK) {
      walletInfo = await MetamaskWebProvider.connect(dispatch, walletInfo);
    }
    // if (providerType === PROVIDER_EVERWALLET) {
    //   walletInfo = await EverWalletWebProvider.connect(dispatch, walletInfo);
    // }
    console.log(walletInfo, providerType, "providerType");
    if (walletInfo && walletInfo.isConnected)
      localStorageObj.set("providerType", providerType);
    return walletInfo;
  }
);

const initialState = {
  walletInfo: {
    accountAddress: null,
    balance: null,
    isConnected: false,
    providerType: null,
    networkChainId: null,
  },
  showNetworkSelectPopup: false,
  // showWalletTypePopup: false,
  // showWalletInfoPopup: false,
  // showWalletDisconnectPopup: false,
  status: "idle",
  error: null,
};

export const walletServiceProvider = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    accountChanged: (state, accounts) => {
      if (accounts.length > 0) state.walletInfo.accountAddress = accounts[0];
    },
    networkChanged: (state, action) => {
      state.walletInfo.networkChainId = parseInt(action.payload);
    },
    // walletTypeDialogToggle: (state) => {
    //   state.showWalletTypePopup = !state.showWalletTypePopup;
    // },
    networkSelectDialogToggle: (state) => {
      state.showNetworkSelectPopup = !state.showNetworkSelectPopup;
    },
    providerSelected: (state, provider) => {
      state.providerType = provider;
    },
    // walletDisconnectDialogToggle: (state) => {
    //   state.showWalletDisconnectPopup = !state.showWalletDisconnectPopup;
    // },
    // walletInfoDialogToggle: (state) => {
    //   state.showWalletInfoPopup = !state.showWalletInfoPopup;
    // },
    disconnect: (state) => {
      localStorageObj.remove("providerType");
      state.walletInfo = initialState;
    },
    // setError: (state, action) => {
    //   state.error = action.payload;
    // },
    setWallet: (state, action) => {
      state.walletInfo = { ...action.payload };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(connectWallet.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(connectWallet.fulfilled, (state, action) => {
        state.status = "succeeded";
        if (action.payload) state.walletInfo = action.payload;
        state.showWalletTypePopup = false;
      })
      .addCase(connectWallet.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
        state.showWalletTypePopup = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const {
  accountChanged,
  // walletTypeDialogToggle,
  // walletInfoDialogToggle,
  // walletDisconnectDialogToggle,
  networkSelectDialogToggle,
  providerSelected,
  disconnect,
  // setError,
  networkChanged,
  setWallet,
} = walletServiceProvider.actions;

export default walletServiceProvider.reducer;
