export const TICK_MAX = 1200;
export const TICK_STEP = 200;

export const IS_DEV = false;

export const TOKEN_NAME = 'CrossToken';

export const DEF_TESTNET_CHAINS = [
  {
    chainId: 420,
    name: "Optimism",
    sendChainId: 420,
    chainName: "Optimism Goerli Testnet",
    logo: "assets/img/custom2/Group 1000002524.svg",
    topMenuLogo: "assets/img/custom2/Group 1000002558.svg",
    backGround: "assets/img/custom2/Frame (3).png",
    groupImg: "assets/img/custom2/Group OPTIMIZM.svg",
    mobGroupImg: "assets/img/custom2/Group 1000002600.svg",
    val: 0,
    claimContractAddress: "0x2dd7D0a530BC2cBD5d404aA77dda81F8f38c9A6A",
    claimTokenAddress: "0xCae40C1Ad0A401cc3Fc20cE3e28ac768FA422161",

    rpcUrls: ["https://goerli.optimism.io"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://goerli-optimism.etherscan.io"],
    checkUrl: "https://api-goerli-optimistic.etherscan.io/",
    apiKey: "59439IY7RQHZJ5KW3BY3C6YKJUSSH7HQXW",
  },
  {
    chainId: 80001,
    name: "Polygon",
    sendChainId: 8001,
    chainName: "Mumbai",
    logo: "assets/img/custom2/Vector (12).svg",
    topMenuLogo: "assets/img/custom2/icons/Polygon.svg",
    backGround: "assets/img/custom2/Frame (5).png",
    groupImg: "assets/img/custom2/Group_POLYGON.svg",
    mobGroupImg: "assets/img/custom2/Group 1000002599.svg",
    val: 0,
    claimContractAddress: "0x5B732fE1565775a1404186f9F57A8b8F5fabDd64",
    claimTokenAddress: "0x1679467004A2C0CD2FCF07580fE483E20bc9E7ac",

    rpcUrls: [
      "https://rpc-mumbai.maticvigil.com/",
      "https://matic-testnet-archive-rpc.bwarelabs.com",
      "https://polygon-testnet.public.blastapi.io	",
      "https://polygontestapi.terminet.io/rpc",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"],
    checkUrl: "https://api-testnet.polygonscan.com/",
    apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",
  },
  {
    chainId: 4002,
    name: "Fantom",
    sendChainId: 4002,
    chainName: "Fantom Testnet",
    logo: "assets/img/custom2/Vector (11).svg",
    topMenuLogo: "assets/img/custom2/icons/Celo-24x24.svg",
    backGround: "assets/img/custom2/Frame (4).png",
    groupImg: "assets/img/custom2/Group_FANTOM.svg",
    mobGroupImg: "assets/img/custom2/Group 1000002591.svg",
    val: 0,
    claimContractAddress: "0xa1cdca3200B01CAC634764991516c271e1C6f98b",
    claimTokenAddress: "0x5B732fE1565775a1404186f9F57A8b8F5fabDd64",

    rpcUrls: ["https://rpc.ankr.com/fantom_testnet"],
    nativeCurrency: {
      name: "FTM",
      symbol: "FTM", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://testnet.ftmscan.com"],
    checkUrl: "https://api-testnet.polygonscan.com/",
    apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",
  },
];

export const DEF_MAINNET_CHAINS = [
  {
    chainId: 10,
    name: "Optimism",
    sendChainId: 10,
    chainName: "Optimism",
    logo: "assets/img/custom2/Group 1000002524.svg",
    topMenuLogo: "assets/img/custom2/Group 1000002558.svg",
    backGround: "assets/img/custom2/Frame (3).png",
    groupImg: "assets/img/custom2/opt-account.svg",
    mobGroupImg: "assets/img/custom2/optimism-account-mobile.svg",
    val: 0,

    rpcUrls: ["https://mainnet.optimism.io"],
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://optimistic.etherscan.io"],

    checkUrl: "https://api-goerli-optimistic.etherscan.io/",
    apiKey: "59439IY7RQHZJ5KW3BY3C6YKJUSSH7HQXW",

    claimContractAddress: "0x6156633ECD9a279e8e4F2FaB35615A24Bb487529",
    claimTokenAddress: "0x9b977490874f4B6e755694143C74d45A9c955608",
  },
  {
    chainId: 137,
    name: "Polygon",
    sendChainId: 137,
    chainName: "Polygon",
    logo: "assets/img/custom2/Vector (12).svg",
    topMenuLogo: "assets/img/custom2/icons/Polygon.svg",
    backGround: "assets/img/custom2/Frame (5).png",
    groupImg: "assets/img/custom2/poly-account.svg",
    mobGroupImg: "assets/img/custom2/poly-account-mobile.svg",
    val: 0,

    rpcUrls: [
      "https://polygon-rpc.com/",
      "https://rpc-mainnet.maticvigil.com/",
    ],
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC", // 2-6 characters long
      decimals: 18,
    },
    blockExplorerUrls: ["https://polygonscan.com"],

    checkUrl: "https://api-testnet.polygonscan.com/",
    apiKey: "9EJ8QGQI17F6563HASRB2KYF5AN999I82N",

    claimContractAddress: "0x6156633ECD9a279e8e4F2FaB35615A24Bb487529",
    claimTokenAddress: "0x9b977490874f4B6e755694143C74d45A9c955608",
  },
  {
    chainId: 56,
    name: "BSC",
    sendChainId: 56,
    chainName: "BSC",
    logo: "assets/img/custom2/bsc-big-logo.svg",
    topMenuLogo: "assets/img/custom2/bsc-logo.svg",
    backGround: "assets/img/custom2/bsc-fon.png",
    groupImg: "assets/img/custom2/bsc-account.svg",
    mobGroupImg: "assets/img/custom2/bsc-account-mobile.svg",
    val: 0,

    nativeCurrency: {
      name: "BNB",
      symbol: "BNB", // 2-6 characters long
      decimals: 18,
    },
    rpcUrls: ["https://bsc-dataseed.binance.org"],
    blockExplorerUrls: ["https://bscscan.com"],
    claimContractAddress: "0x6156633ECD9a279e8e4F2FaB35615A24Bb487529",
    claimTokenAddress: "0x9b977490874f4B6e755694143C74d45A9c955608",
  },
  // {
  //   chainId: 250,
  //   name: "Fantom",
  //   sendChainId: 250,
  //   chainName: "Fantom Opera",
  //   logo: "assets/img/custom2/Vector (11).svg",
  //   topMenuLogo: "assets/img/custom2/icons/Celo-24x24.svg",
  //   backGround: "assets/img/custom2/Frame (4).png",
  //   groupImg: "assets/img/custom2/Group_FANTOM.svg",
  //   mobGroupImg: "assets/img/custom2/Group 1000002591.svg",
  //   val: 0,

  //   nativeCurrency: {
  //     name: "FTM",
  //     symbol: "FTM", // 2-6 characters long
  //     decimals: 18,
  //   },
  //   rpcUrls: [
  //     // "https://fantom-mainnet.gateway.pokt.network/v1/lb/62759259ea1b320039c9e7ac",
  //     "https://rpc.fantom.network",
  //     "https://rpc.ankr.com/fantom",
  //   ],
  //   blockExplorerUrls: ["https://ftmscan.com"],

  // },
];
//

//
